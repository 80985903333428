import React from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';

// React icons
// core components
import { onLogoutStart } from '@allconnect/store/lib/user/user.actions';
import { paymentReset } from '@allconnect/store/lib/payment/payment.actions';
import { connect } from 'react-redux';
import { Parallax } from '@jackywxd/shared-material-ui';
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
// Sections for this page
import Button from 'components/CustomButtons';
// const dashboardRoutes = [];

const useStyles = makeStyles(landingPageStyle);

const SignoutPage = ({ signoutStart, paymentReset }) => {
  const classes = useStyles();
  return (
    <Layout>
      <Parallax filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <h2>
            <FormattedMessage
              id="signout.title"
              defaultMessage="Are you sure?"
            />
          </h2>
          <br />
          <Button
            size="lg"
            round
            color="google"
            onClick={() => {
              signoutStart();
              paymentReset();
              navigate('/');
            }}
          >
            <FormattedMessage
              id="signout.yes"
              defaultMessage="Yes, sign me out"
            />
          </Button>
          {'          '}
          <Button
            size="lg"
            round
            color="facebook"
            onClick={() => {
              navigate('/app/home');
            }}
          >
            <FormattedMessage
              id="signout.no"
              defaultMessage="No, bring me back"
            />
          </Button>
        </div>
      </Parallax>
    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  signoutStart: () => dispatch(onLogoutStart()),
  paymentReset: () => dispatch(paymentReset()),
});

export default connect(null, mapDispatchToProps)(SignoutPage);
